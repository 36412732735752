import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue/dist/vue.esm';
import OwnerInformation from './OwnerInformation.vue';
import { orderBy } from 'lodash';
import es6Promise from 'es6-promise';
import setupCSRFToken from '../setupCSRFToken';
import stickybits from 'stickybits';

window.Vue = Vue;
window.addEventListener('DOMContentLoaded', setupCSRFToken);

es6Promise.polyfill();

document.addEventListener('DOMContentLoaded', () => {
  var element = document.querySelector('#owner-information');

  if (element !== null) {
    new Vue({
      components: {
        'mbc-owner-information': OwnerInformation
      },
      el: element
    });
  }

  stickybits("#merchants_navbar")
});
