<template>
  <div>
    <h3>Owner Information</h3>
    <div v-if="errors.length > 0">
      <div class="alert alert-danger">
        <ul>
          <li v-for="error in errors">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
    <table class="table table-striped table-hover table-sm">
      <thead>
        <th>Name</th>
        <th>Ownership %</th>
        <template v-if="employee">
          <th>Credit Score</th>
          <th>Last Pull</th>
          <th>Credit Report Alerts</th>
        </template>
        <th>Address1</th>
        <th>Address2</th>
        <th>City</th>
        <th>State</th>
        <th>Zip</th>
      </thead>
      <tbody v-show="this.loading">
        <h3>Loading...</h3>
      </tbody>
      <tbody v-show="!this.loading">
        <tr :data-owner-id="owner.id" v-for="owner in orderedOwners">
          <td>{{ owner.name }}</td>
          <td>{{ owner.ownership_percentage }}</td>
          <template v-if="employee">
            <td v-if="owner.mismatched_ssn">
              Provided SSN does not match SSN on Credit Report. Please visit the <a :href="creditReportsLink"> Credit Puller </a> to find the report.
            </td>
            <td v-else-if="owner.link">
              <span :class="creditType(owner)" aria-hidden="true"></span>
              <a :href="owner.link" :title="ownerLinkTitle(owner)">
                <button class="btn btn-sm btn-default">{{ parseInt(owner.score) || 'N/A' }}</button>
              </a>
              <button @click="repull(owner, $event)" type="button" class="btn btn-sm btn-default repull-credit" aria-label="Left Align">
                Repull
              </button>
            </td>
            <td v-else>
              <span class="glyphicon glyphicon-whitespace">&nbsp;</span>
              <button @click="pull(owner, $event)" class="btn btn-sm btn-default">Pull</button>
            </td>
            <td>{{ owner.last_pull_date }}</td>
            <td>{{ formattedCreditReportAlerts(owner) }}</td>
          </template>

          <td>{{ owner.address1 }}</td>
          <td>{{ owner.address2 }}</td>
          <td>{{ owner.city }}</td>
          <td>{{ owner.state }}</td>
          <td>{{ owner.zip }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['creditPullerUri', 'principalId', 'employee'],
  data() {
    return {
      owners: [],
      loading: true,
      errors: []
    }
  },
  methods: {
    creditType(owner) {
      if (owner.good_standing === false) {
        return 'bi bi-x-lg';
      }
      else if (owner.good_standing === true) {
        return 'bi bi-check-lg';
      }
      else {
        return '';
      }
    },
    repull(owner, event) {
      var base = `/principals/${this.principalId}/credit_scores/${encodeURIComponent(owner.id)}/repull.json`;
      var params = `?name=${owner.name}&address1=${owner.address1}&address2=${owner.address2}&city=${owner.city}&state=${owner.state}&zip=${owner.zip}`;
      var repull_url = base.concat(params);

      axios.get(repull_url).then(response => {
        if(response.data.success === true) {
          owner.score = response.data.score;
          owner.link = response.data.link;
          owner.last_pull_date = response.data.last_pull_date;
          owner.good_standing = response.data.commercial_good_standing;
          owner.mismatched_ssn = response.data.mismatched_ssn;
          owner.credit_report_alerts = response.data.credit_report_alerts;
          axios.patch(`/principals/${this.principalId}.json`, { principal: { owners_attributes: { id: owner.id, mismatched_ssn: owner.mismatched_ssn } }})
        } else {
          this.errors.push(response.data.errors);
        }
      });
    },
    pull(owner, event) {
      event.target.disabled = true;
      axios.get(`/principals/${this.principalId}/credit_scores/${encodeURIComponent(owner.id)}/pull.json`).then(response => {
        if(response.data.success == true) {
          owner.score = response.data.score;
          owner.link = response.data.link;
          owner.last_pull_date = response.data.last_pull_date;
          owner.good_standing = response.data.commercial_good_standing;
          owner.mismatched_ssn = response.data.mismatched_ssn;
          owner.credit_report_alerts = response.data.credit_report_alerts;
          axios.patch(`/principals/${this.principalId}.json`, { principal: { owners_attributes: { id: owner.id, mismatched_ssn: owner.mismatched_ssn } }})
        } else {
          this.errors.push(response.data.errors);
        }
      });
    },
    ownerLinkTitle(owner) {
      return `Last pulled on ${owner.last_pull_date}. Click here to view.`
    },
    formattedCreditReportAlerts(owner) {
      return (owner.credit_report_alerts ?? []).join("; ");
    },
  },
  computed: {
    orderedOwners() {
      return _.orderBy(this.owners, ['ownership_percentage', 'name'], ['desc'])
    },
    creditReportsLink() {
      return `${this.creditPullerUri}/credit_reports?q[s]=created_at+desc`
    }
  },
  beforeMount() {
    var self = this; // save "this" since "this" changes in the context of the below function
    // Make an API request internally to retrieve owners
    // Add score, link, and last_pull_date at same time to make them reactive
    axios.get(`/principals/${this.principalId}.json`).then(response => {
      response.data.owners.map((owner) => {
        owner.score = '';
        owner.link = '';
        owner.last_pull_date = '';
        self.owners.push(owner);
      });

      if (this.employee) {
        // Make an API request to retrieve credit report information
        this.owners.forEach((owner, index) => {
          axios.get(`/principals/${this.principalId}/credit_scores/${encodeURIComponent(owner.id)}`).then(response => {
            if(response.data.success == true) {
              owner.score = response.data.score;
              owner.link = response.data.link;
              owner.last_pull_date = response.data.last_pull_date;
              owner.good_standing = response.data.commercial_good_standing;
              owner.credit_report_alerts = response.data.credit_report_alerts;
            } else {
              this.errors.push(response.data.errors);
            }
          })
          .catch(e => {
            console.log(e);
          });
        })
      }
      self.loading=false;
    })
    .catch(e => {
      console.log(e);
    });
  }
}
</script>

<style scoped>
.glyphicon-ok {
  color: green;
}
.glyphicon-remove {
  color: red;
}
</style>
